import React from "react";
import Helmet from "react-helmet";
import { graphql } from "gatsby";
import Layout from "../layout/layout.jsx";
import ProjectCover from "../components/ProjectCover/ProjectCover";
import config from "../../data/SiteConfig";
import { Container, Columns, Column, Section } from 'bloomer';

class Index extends React.Component {
  render() {
    const projects = this.props.data.projects.edges;

    return (
      <Layout>
        <Helmet>
          <title>{config.siteTitle}</title>
        </Helmet>
        <Section className="has-navbar-padding">

          <Container>
            <hr />
            <h1 class="title is-1" style={{ marginBottom: "1em" }}>dark <span style={{ fontWeight: 300 }}>inventions</span></h1>
            <p class="subtitle is-5" style={{ maxWidth: "540px", marginRight: "auto" }}>{config.strapline}</p>
            <hr />
                <Columns isMobile isMultiline>
                  {projects.map((node) => {
                    return (
                      <Column isSize={{ mobile:12, tablet:6, desktop: 4, widescreen:3 }}>
                        <ProjectCover project={node} />
                      </Column>
                    )
                  })}
                </Columns>

          </Container>
        </Section>
      </Layout>
    );
  }
}

export default Index;

/* eslint no-undef: "off" */
export const pageQuery = graphql`
  query IndexQuery {
    projects: allMarkdownRemark(
      limit:9,
      filter: {fields: {template: {eq: "projects"}}},
      sort:{fields:[frontmatter___year], order:DESC}
      ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            cover {
              childImageSharp {
                thumbnail: fluid(maxWidth: 300, maxHeight: 300) {
                    ...GatsbyImageSharpFluid
                }
              }
            }
            title
            tagline
            year
            repertoire {
              title
              composer
            }
          }
        }
      }
    }
  }
`;
